import React from 'react';
import { Helmet } from 'react-helmet-async';


const Privacy = () => {


  return (
    <>
    <Helmet>
      <title>Privacy</title>
      <meta NAME="robots" CONTENT="noindex,nofollow" />
    </Helmet>

    <section className="relative overflow-hidden md:pt-22 pt-18 md:pb-24 pb-16">
      <div className="container relative">
        <div className="grid grid-cols-1 items-center mt-8 gap-[30px] relative">
        <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
          <p className="mb-2">Last updated: </p>
          <p className="mb-4">We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at hello@truth-dare-game.com.</p>

          <h2 className="text-3xl font-bold mb-2">Information We Collect</h2>
          <p className="mb-2">As part of our service, we collect information that you provide to us directly:</p>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2"><strong>Payment Information:</strong> We collect payment information for purchases made on our website. This information is processed securely by our payment processing partners and is not stored on our servers.</li>
            <li className="mb-2"><strong>Email Communications:</strong> If you initiate contact with us, we collect your email address and any other information you provide in your communication to respond to your queries or comments.</li>
          </ul>

          <h2 className="text-3xl font-bold mb-2">Information Automatically Collected</h2>
          <p className="mb-4">We automatically collect certain information when you visit, use, or navigate our website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, referring URLs, device name, country, location, information about how and when you use our website, and other technical information. This information is primarily needed to maintain the security and operation of our website, and for our internal analytics and reporting purposes.</p>

          <h2 className="text-3xl font-bold mb-2">How We Use Your Information</h2>
          <p className="mb-2">We use your information in various ways, including to:</p>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Process your transactions.</li>
            <li className="mb-2">Respond to your inquiries and provide customer service.</li>
            <li className="mb-2">Send you administrative information, such as changes to our terms, conditions, and policies.</li>
            <li className="mb-2">Analyze how our website is used to improve our service.</li>
          </ul>

          <h2 className="text-3xl font-bold mb-2">Google Analytics 4</h2>
          <p className="mb-4">We use Google Analytics 4 for website analytics. Google Analytics 4 provides insights into our site's traffic and usage patterns. The data collected through Google Analytics is stored and processed by Google, in accordance with their privacy policy.</p>

          <h2 className="text-3xl font-bold mb-2">Sharing Your Information</h2>
          <p className="mb-4">We do not share or sell your personal information with third parties for their marketing purposes without your explicit consent. We may share your information with our service providers who assist us in providing the service, such as payment processors and analytics providers, under confidentiality agreements.</p>

          <h2 className="text-3xl font-bold mb-2">Your Privacy Rights</h2>
          <p className="mb-4">Depending on your location, you may have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability.</p>

          <h2 className="text-3xl font-bold mb-2">Contact Us</h2>
          <p className="mb-4">If you have any questions or comments about this policy, you may email us at hello@truth-dare-game.com.</p>
        </div>      
      </div>      
    </section>

    </>
);
}

export default Privacy;
