import React from 'react';

import { Footer, Dropdown} from 'flowbite-react';

import { useTranslation } from 'react-i18next';
  

const MyFooter = ({loading, setLoading }) => {

  const { t } = useTranslation();
  
  const { i18n } = useTranslation();

  const changeLanguage = async (value) => {
    if (!loading) {
      setLoading(true);
      
      const res = await fetch(`/setOptions?language=${value}`, {
        method: 'GET',
        credentials: 'include',
      });
      console.log(res)
      setLoading(false);
      
      i18n.changeLanguage(value);

    }
    
  };

  return (
    <Footer container>
      <Footer.Copyright href="#" by="Fun night" year={2023} />
      <Footer.LinkGroup>
        <Footer.Link href="/privacy">Privacy Policy</Footer.Link>
        {/* <Footer.Link href="#">Contact</Footer.Link> */}

        <Dropdown onChange={changeLanguage} label={t('languages_' + i18n.language)} placement="top" inline>
          <Dropdown.Item onClick={() => changeLanguage('en')} value="en">{t('languages_en')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('de')} value="de">{t('languages_de')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('it')} value="it">{t('languages_it')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('es')} value="es">{t('languages_es')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('fr')} value="fr">{t('languages_fr')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('no')} value="no">{t('languages_no')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('da')} value="da">{t('languages_da')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('sv')} value="sv">{t('languages_sv')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('pt')} value="pt">{t('languages_pt')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('ru')} value="ru">{t('languages_ru')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('ko')} value="ko">{t('languages_ko')}</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('nl')} value="nl">{t('languages_nl')}</Dropdown.Item>
        </Dropdown>

      </Footer.LinkGroup>


    </Footer>
  );
};

export default MyFooter;
