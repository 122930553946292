import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

const Home = () => {

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  let root_lang_path = '/';
  const availableLanguages = ['de', 'it', 'es', 'fr', 'no', 'da', 'sv', 'pt', 'ru', 'ko', 'nl'];

  if (availableLanguages.includes(i18n.language)) {
    root_lang_path = `/${i18n.language}/`;
  }


  return (
    <>
    <Helmet>
      <title>{t('seo_title')}</title>
      <meta name="description" content={t('seo_description')} />
      <meta name="keywords" content={t('seo_keywords')} />
      <meta name="author" content={t('seo_author')} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://truth-dare-game.com/" />
      <meta property="og:title" content={t('seo_og_title')} />
      <meta property="og:description" content={t('seo_og_description')} />
      <meta property="og:image" content="/logo512.png" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://truth-dare-game.com/" />
      <meta property="twitter:title" content={t('seo_twitter_title')} />
      <meta property="twitter:description" content={t('seo_twitter_description')} />
      <meta property="twitter:image" content="/logo512.png" />

      <link rel="canonical" href={`https://truth-dare-game.com${window.location.pathname}`} />
      {availableLanguages.filter(lang => lang !== i18n.language).map(lang => (
        <link key={lang} rel="alternate" href={`https://truth-dare-game.com/${lang}/`} hreflang={lang} />
      ))}

    </Helmet>

    <section className="relative overflow-hidden md:pt-22 pt-18 md:pb-24 pb-16">
      <div className="container relative">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px] relative">
          <div className="md:me-6">
            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white">
              {t('landing_h1_section_1')}
              <span className="after:absolute after:end-0 after:start-0 after:bottom-3 after:-rotate-6 after:h-2 after:w-auto after:rounded-md after:bg-indigo-600/20 relative text-indigo-600">
                {t('landing_h1_section_2')}
              </span>
              {t('landing_h1_section_3')}
            </h4>
            <p className="text-slate-700 text-xl max-w-xl">{t('landing_description')}</p>
            <div className="mt-6 flex justify-center">
              <a href={`${root_lang_path}play`} className="py-4 px-8 inline-block font-semibold tracking-wide border align-middle duration-500 text-lg text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2">
                {t('landing_play_button')}
              </a>
            </div>            
          </div>
          <div className="relative">
            <img width="460" src="/assets/images/product_shot_1.png" className="mx-auto" alt=""/>
            {/* Decorative elements */}
          </div>
        </div>
      </div>

      <div className="container relative md:mt-24 mt-16">
        <div className="grid grid-cols-3 gap-4 pb-8">
          {/* Easy as always */}
          <div>
            <h4 className="text-center mb-2 md:text-2xl md:leading-normal text-xl leading-normal font-semibold">{t('landing_easy_title')}</h4>
            <p className="text-slate-700 max-w-xl mx-auto">{t('landing_easy_description')}</p>
          </div>
          {/* Set your Rules */}
          <div>
            <h4 className="text-center mb-2 md:text-2xl md:leading-normal text-xl leading-normal font-semibold">{t('landing_set_rules_title')}</h4>
            <p className="text-slate-700 max-w-xl mx-auto">{t('landing_set_rules_description')}</p>
          </div>
          {/* Personalize It */}
          <div>
            <h4 className="text-center mb-2 md:text-2xl md:leading-normal text-xl leading-normal font-semibold">{t('landing_personalize_title')}</h4>
            <p className="text-slate-700 max-w-xl mx-auto">{t('landing_personalize_description')}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 pb-8">
          {/* Discover a New World of Connection */}
          <h3 className="text-center mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">{t('landing_discover_world_title')}</h3>
          <p className="text-slate-700 max-w-xl mx-auto">{t('landing_discover_world_description1')}</p>
          <p className="text-slate-700 max-w-xl mx-auto">{t('landing_discover_world_description2')}</p>
        </div>
        <div className="grid grid-cols-1 pb-8">
          {/* Account not needed */}
          <h3 className="text-center mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">{t('landing_account_not_needed_title')}</h3>
          <p className="text-slate-700 max-w-xl mx-auto">{t('landing_account_not_needed_description')}</p>
        </div>
        <div className="grid grid-cols-1 pb-8 text-center">
          {/* Limitless 24h for less than a machiatto */}
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">{t('landing_limitless_24h_title')}</h3>
          <p className="text-slate-700 max-w-xl mx-auto">{t('landing_limitless_24h_description')}</p>
          <div className="mt-6">
          <a href={`${root_lang_path}play`} className="py-4 px-8 inline-block font-semibold tracking-wide border align-middle duration-500 text-lg text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2">
              {t('landing_play_button')}
            </a>
          </div>
        </div>
      </div>
    </section>

    </>
);
}

export default Home;
