// App.js or a high-level component
import React, {useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Outlet, useLocation } from 'react-router-dom';

import ReactGA from "react-ga4";

// import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";


import Home from './components/Home';
import Privacy from './components/Privacy'; 
import Menu from './components/Menu';
import Chat from './components/Chat';
import MyFooter from './components/MyFooter'; 

import './App.css';




function LanguageSetter() {
  
  const { i18n } = useTranslation();
  const { lang } = useParams();
  
  useEffect(() => {
    const availableLanguages = ['en', 'de', 'it', 'es', 'fr', 'no', 'da', 'sv', 'pt', 'ru', 'ko', 'nl'];
    const language = availableLanguages.includes(lang) ? lang : 'en';
    if(i18n.language !== language) {
      // Removed unused variable 'res'
      fetch(`/setOptions?language=${language}`, {
        method: 'GET',
        credentials: 'include',
      });
      
      i18n.changeLanguage(language);
    }

  }, [lang, i18n]);

  return <Outlet />; // Render the child routes
}


const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  return null;
};

const App = () => {

  
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [options, setOptions] = useState([]);
  const [sessionData, setSessionData] = useState([]);

  // Extract invite_code from URL
  const params = new URLSearchParams(window.location.search);
  const invite_code = params.get('invite_code');
  
  useEffect(() => {

    ReactGA.initialize("G-4EVH0NKC0S");
    // ReactGA.pageview(window.location.pathname + window.location.search);

    
    const backend_host = ''

    const fetchSession = async (fingerprint = null) => {
      
      let session_url = `${backend_host}/session`
      
      if(invite_code)
        session_url = `${backend_host}/session?invite_link=${invite_code}`
      
      const res = await fetch(session_url, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await res.json();

      setMessages(data.history);
      setOptions(data.options);
      setSessionData(data.sessionData);
      
    };

    fetchSession(null);

    // Skipping the fingerprint
    // getCurrentBrowserFingerPrint().then((fingerprint) => {
    //   // fingerprint is your unique browser id.
    //   // This is well tested
    //   // the result you receive here is the combination of Canvas fingerprint and audio fingerprint.
    //   fetchSession(fingerprint);
    // })

    
  }, [invite_code]);

  return (
    <HelmetProvider>
      <Router>
        <TrackPageView />
        <Menu sessionData={sessionData}/>    
          <Routes>
            {/* Default routes (English) */}
            <Route index path="/" element={<Home />} />
            <Route path="/play" element={<Chat messages={messages} setMessages={setMessages} options={options} setOptions={setOptions} loading={loading} setLoading={setLoading} />} />
            <Route path="/privacy" element={<Privacy />} />

            {/* Language-specific routes */}
            <Route path="/:lang" element={<LanguageSetter />}>
              <Route index element={<Home />} />
              <Route path="play" element={<Chat messages={messages} setMessages={setMessages} options={options} setOptions={setOptions} loading={loading} setLoading={setLoading} />} />
              <Route path="privacy" element={<Privacy />} />
            </Route>
          </Routes>      
          <MyFooter loading={loading} setLoading={setLoading} />      
      </Router>
    </HelmetProvider>
  );
}


const AppWrapper = () => (
  <App />
);

export default AppWrapper;