import React from 'react';
import { Button, Navbar } from 'flowbite-react';

import { useTranslation } from 'react-i18next';


const Menu = ({sessionData}) => {

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const purchasedDate = sessionData.purchased ? new Date(sessionData.purchased) : null;
  
  let root_lang_path = '/';
  const availableLanguages = ['de', 'it', 'es', 'fr', 'no', 'da', 'sv', 'pt', 'ru', 'ko', 'nl'];

  const price = 3.9

  if (availableLanguages.includes(i18n.language)) {
    root_lang_path = `/${i18n.language}/`;
  }
  
  return (
    <Navbar fluid rounded>
      <Navbar.Brand href={root_lang_path}>
        <img src="/assets/images/funquiz-logo-168x168.png" className="mr-3 h-6 sm:h-9" alt="Truth or Dare Game Logo" />
        <span className="self-center whitespace-nowrap text-lg sm:text-xl font-semibold dark:text-white">{t('app_name')}</span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        {window.location.pathname.endsWith('/play') ? 
          (purchasedDate && ((new Date() - purchasedDate) / (1000 * 60 * 60)) < 24) ?
            <div className="sm:text-l text-green-500 border-green-300 border-2 rounded-lg px-3 py-2 cursor-pointer">{t('active_24_hours_pass')}</div> :
            <Button className="sm:text-xl rounded-lg" href='/quick-purchase'>{t('upgrade_cta', { price: price })}</Button> 
          : 
          <Button href={`${root_lang_path}play`}>{t('landing_play_button')}</Button>
        }
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link href={root_lang_path} active={window.location.pathname === root_lang_path}>{t('home_menu')}</Navbar.Link>
        <Navbar.Link href={`${root_lang_path}play`} active={window.location.pathname === `${root_lang_path}play`}>{t('game_menu')}</Navbar.Link>
        {/* <Navbar.Link href={`${root_lang_path}contact`} active={window.location.pathname === `${root_lang_path}contact`}>{t('contact_menu')}</Navbar.Link> */}
        
      </Navbar.Collapse>
    </Navbar>    
  );
};

export default Menu;


