import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import ReactGA from "react-ga4";

import ButtonSection from './ButtonSection';

const Chat = ({ messages, setMessages, options, setOptions, loading, setLoading }) => {


  const { t } = useTranslation();

  const availableLanguages = ['de', 'it', 'es', 'fr', 'no', 'da', 'sv', 'pt', 'ru', 'ko', 'nl'];

  const handleClick = async (action) => {      
      if (!loading) {
        setLoading(true);
        
        // Send a general interaction event to GA4
        ReactGA.event({
          category: "interaction",
          action: "action_requested",
          label: "Fun Quiz Web App", // optional
        });

        // Send the requested action event to GA4
        ReactGA.event({
          category: "interaction",
          action: action,
          label: "Fun Quiz Web App", // optional
        });

        if (action === 'Random') {
          action = Math.random() < 0.5 ? 'Truth' : 'Dare';
        }

        const ask = {
          time: "",
          role:"user",
          msg: action === 'Dare' ? t('dare_chat') : action === 'Truth' ? t('truth_chat') : action === 'Colder' ? t('colder_chat') : t('spicier_chat')
        }
        setMessages(prevMessages => [...prevMessages, ask]);
        
        const res = await fetch(`/generate?action=${action}`, {
          method: 'GET',
          credentials: 'include',
        });

        const chatView = document.getElementById('chatview');
        let lastElement = chatView.querySelector('ul').lastElementChild;
        lastElement.scrollIntoView(true);


        const data = await res.json();
        
        if (data.help) {
          data.msg = t(data.msg);
        }

        if (data.event) {
          if(data.event==="limit_reached") {

            data.msg = `${data.msg} <a href="/quick-purchase" class="inline-block px-4 py-2 text-white text-lg font-bold bg-indigo-400 rounded hover:bg-indigo-500">🛒 ${t('help_text_purchase_cta')}</a>`
            // Send a custom for limit reached
            ReactGA.event({
              category: "interaction",
              action: data.event,
              label: "Fun Quiz Web App", // optional
            });          

            
          }
        }

        setMessages(prevMessages => [...prevMessages, data]);
        setLoading(false);
        chatView.scrollTop = chatView.scrollHeight;

        lastElement = chatView.querySelector('ul').lastElementChild;
        lastElement.scrollIntoView(true);

        // // Send a response event
        // ReactGA.event({
        //   category: "response",
        //   action: action
        // });
      }
  };

  useEffect(() => {
    const eventSource = new EventSource('/game-updates');
  
    eventSource.onmessage = function(event) {
      const game = JSON.parse(event.data);
      console.log("Received update", game);
      // Update your state or UI with the new game data
    };
  
    return () => {
      eventSource.close();
    };
  }, []);  

  return (
    <>
      <Helmet>
        <title>{t('seo_title')}</title>
        <meta name="description" content={t('seo_description')} />
        <meta name="keywords" content={t('seo_keywords')} />
        <meta name="author" content={t('seo_author')} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://truth-dare-game.com/" />
        <meta property="og:title" content={t('seo_og_title')} />
        <meta property="og:description" content={t('seo_og_description')} />
        <meta property="og:image" content="/logo512.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://truth-dare-game.com/" />
        <meta property="twitter:title" content={t('seo_twitter_title')} />
        <meta property="twitter:description" content={t('seo_twitter_description')} />
        <meta property="twitter:image" content="/logo512.png" />

        <link rel="canonical" href={`https://truth-dare-game.com${window.location.pathname}`} />
        {availableLanguages.filter(lang => lang !== t.language).map(lang => (
          <link key={lang} rel="alternate" href={`https://truth-dare-game.com/${lang}/play`} hreflang={lang} />
        ))}
    </Helmet>
    <div className="flex-1 overflow-y-auto">               
      <div className="chat-view grid-cols-1 p-1 grid mb-5">
          <div className="shadow-sm shadow dark:shadow-slate-800 rounded bg-gray-50 dark:bg-slate-900 p-2">

            <ButtonSection messages={messages} setMessages={setMessages} options={options} setOptions={setOptions} loading={loading} setLoading={setLoading}/>
            
            <div id="chatview" className="p-10 text-xl overflow-auto" style={{ maxHeight: '500px' }}>
              <ul id='messages' className="text-lg">                  
              {messages && messages.map((item, index) => (
                <li key={index} className={`p-2 ${item.role === 'user' ? 'text-right text-blue-600' : 'text-left'}`}>
                  {item.role === 'help' ? t(item.msg) : <div dangerouslySetInnerHTML={{ __html: item.msg }}></div>}
                </li>
              ))}
              {loading && 
                <li>
                  {t('writing_chat')}
                  <span className="animate-ping">.</span>
                  <span className="animate-ping delay-100">.</span>
                  <span className="animate-ping delay-200">.</span>
                </li>
              }
              
              </ul>
            </div>
            
            <hr/>

            <div className="chat-view container m-auto grid grid-cols-5 gap-2 mt-5">
                <div className="col-span-2 md:col-span-2 lg:col-span-2">
                    <button onClick={() => handleClick('Truth')} id="truth" className="w-full py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-center bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white rounded-md sm:text-lg xs:text-base">{t('truth_button')}</button>
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-1">
                    <button onClick={() => handleClick('Random')} id="random" className="w-full py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-center bg-gray-500/5 hover:bg-gray-500 border-gray-500/10 hover:border-gray-500 text-gray-500 hover:text-white rounded-md sm:text-lg xs:text-base">🎲</button>
                </div>
                <div className="col-span-2 md:col-span-2 lg:col-span-2">
                    <button onClick={() => handleClick('Dare')} id="dare" className="w-full py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-center bg-orange-600/5 hover:bg-orange-600 border-orange-600/10 hover:border-orange-600 text-orange-600 hover:text-white rounded-md sm:text-lg">{t('dare_button')}</button>
                </div>
            </div>

            <div className="col-span-2 flex justify-center mt-5">
                <div  className="col-span-1 md:col-span-1 lg:col-span-1 mx-2">
                    <button onClick={() => handleClick('Colder')} id="colder" className="py-[5px] px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-transparent hover:bg-cyan-500 border-cyan-500 text-cyan-500 hover:text-white rounded-md">{t('colder_button')}</button>
                </div>
                <div className="col-span-1 md:col-span-1 lg:col-span-1 mx-2">
                    <button onClick={() => handleClick('Spicier')} id="spicier" className="py-[5px] px-4 inline-block font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-transparent hover:bg-red-600 border-red-600 text-red-600 hover:text-white rounded-md">{t('spicier_button')}</button>
                </div>
            </div>
          </div>
      </div>
    </div>
    </>
  );
};

export default Chat;
