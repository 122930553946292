import React from 'react';
import { Checkbox, Label, Radio, Accordion } from 'flowbite-react';

import ReactGA from "react-ga4";

import { useTranslation } from 'react-i18next';


const ButtonSection = ({ messages, setMessages, options, setOptions, loading, setLoading }) => {

    const { t } = useTranslation();
  
    const handleOptionsChange = async (groupKey, optionKey, optionValue) => {      
        
        console.log(groupKey+optionKey, optionValue)

        // Send a custom for limit reached
        ReactGA.event({
          category: "interaction",
          action: `${groupKey}_${optionKey}`,
          value: optionValue ? 1 : 0,
          label: "Fun Quiz Web App", // optional
        });

        if (!loading) {
          setLoading(true);
          
          const res = await fetch(`/setOptions?groupKey=${groupKey}&optionKey=${optionKey}&optionValue=${optionValue}`, {
            method: 'GET',
            credentials: 'include',
          }); 
          
          const options = await res.json();
          setOptions(options);
          setLoading(false);
        }
    };

    return (
        <Accordion collapseAll>
            <Accordion.Panel>
                <Accordion.Title className="text-sm">{t('game_options')}</Accordion.Title>
                <Accordion.Content>
  
                    <fieldset className="flex max-w-md flex-col gap-4">
                        <legend className="mb-4">{t('options_contexts_header')}</legend>
                        {options.contexts && Object.keys(options.contexts).map((key, index) => (
                            <div className="flex items-center gap-2" key={index}>
                                <Radio onChange={(e) => handleOptionsChange('contexts', key, e.target.checked)} id={key} name="countries" value={key} defaultChecked={options.contexts[key].enabled} />
                                <Label htmlFor={key}>{t('options_contexts_' + key)}</Label>
                            </div>
                        ))}
                    </fieldset>

                    <div className="flex max-w-md flex-col gap-4 mt-4" id="checkbox">
                        <legend className="mb-4">{t('options_topics_header')}</legend>
                        {options.topics && Object.keys(options.topics).map((key, index) => (
                            <div className="flex items-center gap-2" key={index}>
                                <Checkbox className="bg-current" onChange={(e) => handleOptionsChange('topics', key, e.target.checked)} id={key} {...(options.topics[key].enabled && { defaultChecked: true })}/>
                                
                                <Label htmlFor={key}>{t('options_topics_' + key)}</Label>
                            </div>
                        ))}
                    </div>
                </Accordion.Content>
            </Accordion.Panel>
      </Accordion>

    );
};

export default ButtonSection;