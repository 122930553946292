import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

const initI18n = async () => {
  await i18n
    .use(HttpBackend) // Use the HttpBackend plugin
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
      lng: 'en', // Default language
      fallbackLng: 'en', // Fallback language if the user's language is not available
      backend: {
        loadPath: '/locales/{{lng}}/translation.json', // Path to the translation files
      },
      interpolation: {
        escapeValue: false, // React already safes from XSS
      },
      debug: false,
    });
};

initI18n();

export default i18n;